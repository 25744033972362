<template>
  <div style="background: white">
    <Loading :loadingCounter="loadingCounter" />
    <v-sheet class="overflow-hidden">
      <Loading />
      <NavBar tittle="label.lbl_enteringAchieve" />
      <!-- （画面名）入庫実績登録（検索）-->
      <v-form ref="form" lazy-validation>
        <v-container fluid>
          <v-row class="search-row">
            <div class="search-autocomplete first-search-item">
              <!-- 取引先-->
              <v-autocomplete
                dense
                v-model="suppliersSelected"
                :items="supplierList"
                :label="$t('label.lbl_supplier')"
                :hint="setSrhSupplier()"
                class="txt-single"
                persistent-hint
                :rules="[rules.inputRequired]"
                pa-3
                :error-messages="alertSupplierMessage"
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
            </div>
            <span class="require">*</span>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 入荷元-->
            <div class="search-autocomplete">
              <v-autocomplete
                dense
                v-model="arrivalSelected"
                :items="arrivalList"
                item-text="displayText"
                item-value="value"
                :label="$t('label.lbl_arrival')"
                :hint="setSrhArrival()"
                class="txt-single"
                persistent-hint
                @change="changeArrival"
                :search-input.sync="getSuppliersSelected"
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 入荷No-->
            <div class="textbox-arrivalNo">
              <v-text-field
                outlined
                dense
                v-model="arrivalNo"
                maxlength="50"
                :label="$t('label.lbl_slipNo')"
                class="txt-single"
                clear-icon="mdi-close-circle"
                clearable
              />
            </div>

            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <div class="date-style">
              <!-- 入荷予定日From-->
              <v-text-field
                outlined
                dense
                v-model="arrivalScheduleDateFrom"
                :label="$t('label.lbl_arrivalScheduleDate') + '（From）'"
                @change="changeDateFrom"
                :rules="[rules.yyyymmdd]"
                class="txt-single date-style"
                clear-icon="mdi-close-circle"
                clearable
              >
              </v-text-field>
            </div>
            <span class="require">*</span>

            <div class="calender-btn-area">
              <!-- カレンダー -->
              <v-menu
                v-model="dateMenuFrom"
                :close-on-content-click="false"
                :nudge-right="-100"
                transition="scale-transition"
                offset-y
                min-width="auto"
                class="date-calendar"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" text x-small>
                    <v-icon>mdi-calendar</v-icon>
                  </v-btn>
                </template>
                <v-date-picker
                  v-model="arrivalScheduleDateFromCal"
                  @input="dateMenuFrom = false"
                ></v-date-picker>
              </v-menu>
              <v-btn id="btn-yesterday" class="day-ctrl-btn" @click="prevDate" text x-small>
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn id="btn-tomorow" class="day-ctrl-btn" @click="nextDate" text x-small>
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>

            <!-- カレンダーの間 -->
            <div class="from_to">～</div>

            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <div class="date-style">
              <!-- 入荷予定日To-->
              <v-text-field
                outlined
                dense
                v-model="arrivalScheduleDateTo"
                :label="$t('label.lbl_arrivalScheduleDate') + '（To）'"
                @change="changeDateTo"
                :rules="[rules.yyyymmdd]"
                class="txt-single date-style"
                clear-icon="mdi-close-circle"
                clearable
                :error-messages="alertMessage"
              >
              </v-text-field>
            </div>
            <span class="require">*</span>

            <div class="calender-btn-area">
              <!-- カレンダー -->
              <v-menu
                v-model="dateMenuTo"
                :close-on-content-click="false"
                :nudge-right="-100"
                transition="scale-transition"
                offset-y
                min-width="auto"
                class="date-calendar"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" text x-small>
                    <v-icon>mdi-calendar</v-icon>
                  </v-btn>
                </template>
                <v-date-picker
                  v-model="arrivalScheduleDateToCal"
                  @input="dateMenuTo = false"
                ></v-date-picker>
              </v-menu>
              <v-btn id="btn-yesterday" class="day-ctrl-btn" @click="prevToDate" text x-small>
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn id="btn-tomorow" class="day-ctrl-btn" @click="nextToDate" text x-small>
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>
          </v-row>
          <v-row>
            <!--ボタン領域-->
            <div class="search-row-exeBtn">
              <!--検索ボタン領域-->
              <div class="btn-search-area" style="float: left">
                <!-- 検索ボタン -->
                <v-btn class="get-btn" @click="getApiList(true)">{{ $t("btn.btn_search") }}</v-btn>
                <!--画面クリアボタン-->
                <v-btn class="other-btn" @click="clearScreen()">{{ $t("btn.btn_clear") }}</v-btn>
              </div>
              <div class="btn-area">
                <!--登録処理ボタン-->
                <v-btn class="post-btn" @click="openUpdateDelete()">{{
                  $t("btn.btn_insertprocess")
                }}</v-btn>
              </div>
            </div>
          </v-row>
          <v-row>
            <div class="display-search-result-row">
              <!--検索結果表示-->
              <div class="text-label" style="float: left">
                <span class="toatlNum">総件数：{{ totalCount }}件</span>
              </div>

              <!--表示件数切替部分-->
              <div style="float: right">
                <!--表示件数-->
                <div class="text-label" style="clear: both; float: left">
                  <div>{{ $t("label.lbl_itemsPerPage") }}</div>
                </div>

                <!-- 空白 -->
                <span class="item-spacer" style="float: left">&nbsp;</span>

                <!--表示件数切替ボタン-->
                <div style="float: left">
                  <v-btn-toggle
                    v-model="itemsPerPageBtn"
                    color="blue darken-2"
                    class="toggle black--text"
                    right
                  >
                    <v-btn class="pasing-btn" value="10" @click="u_10_on()" small>
                      {{ 10 }}
                    </v-btn>
                    <v-btn class="pasing-btn" value="50" @click="u_10_clear(50)" small>
                      {{ 50 }}
                    </v-btn>
                    <v-btn class="pasing-btn" value="100" @click="u_10_clear(100)" small>
                      {{ 100 }}
                    </v-btn>
                  </v-btn-toggle>
                </div>
              </div>
            </div>
            <!-- ページング数 -->
          </v-row>
        </v-container>
      </v-form>

      <!-- データテーブル -->
      <v-container fluid>
        <v-data-table
          id="listData"
          fixed-header
          :headers="headerItems"
          :items="inputList"
          :page.sync="page"
          :ripple="false"
          :server-items-length="itemsPerPage"
          disable-filtering
          :hide-default-footer="true"
          height="530px"
          :options.sync="sortOptions"
        >
          <!-- 一覧チェックボックス -->
          <template v-slot:[`item.check`]="{ item, index }">
            <input
              type="checkbox"
              v-model="item.check"
              @click="checkList(item, index)"
              style="transform: scale(2)"
            />
          </template>

          <!-- 伝票No -->
          <template v-slot:[`item.arrivalNo`]="{ item }">
            <div class="listNo-style">{{ item.arrivalNo }}</div>
          </template>
        </v-data-table>

        <!-- ページネーション -->
        <div class="text-center pt-2">
          <v-pagination v-model="page" :length="pageCount" :total-visible="7"></v-pagination>
        </div>
      </v-container>

      <v-navigation-drawer absolute temporary v-model="openMenu">
        <sideMenu></sideMenu>
      </v-navigation-drawer>

      <!-- 確認メッセージダイアログモーダル-->
      <SimpleDialog
        :isShow.sync="infoDialog.isOpen"
        :title="infoDialog.title"
        :message="infoDialog.message"
        :firstPageFlag="infoDialog.firstPageFlag"
      />
      <ConfirmDialog
        :isShow.sync="confirmDialog.isOpen"
        :message="confirmDialog.message"
        :screenFlag="confirmDialog.screenFlag"
        :okAction="confirmDialog.okAction"
        :redMessage="confirmDialog.redMessage"
        :changeFlag="confirmDialog.changeFlag"
      />
      <v-dialog v-model="updateDialogMessage" :max-width="800">
        <!-- 警告ダイアログ -->
        <v-card>
          <v-card-title class="blue-grey lighten-3" primary-title>
            {{ $t("btn.btn_ok") }}
          </v-card-title>
          <v-card-text class="pa-4">
            <p>{{ backMessage }}</p>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="updateOk"> OK </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-sheet>
  </div>
</template>
<script>
import NavBar from "../../components/NavBar.vue";
import Loading from "@/components/loading";
import { getParameter } from "../../assets/scripts/js/GetParameter";
import SimpleDialog from "@/components/SimpleDialog";
import sideMenu from "@/components/Menu";
import { dateTimeHelper } from "../../assets/scripts/js/DateTimeHelper";
import { i18n } from "../../lang/lang.js";
import { commonUtil } from "../../assets/scripts/js/CommonUtil";
import { appConfig } from "../../assets/scripts/js/AppConfig";
import { messsageUtil } from "../../assets/scripts/js/MesssageUtil";
import ConfirmDialog from "@/components/ConfirmDialog";
export default {
  name: appConfig.MENU_ID.P_RCV_ACTUAL_ADD,
  components: {
    Loading,
    SimpleDialog,
    ConfirmDialog,
    sideMenu,
    NavBar,
  },
  props: {
    isMenu: { type: Boolean, default: false },
  },
  data: () => ({
    checkFlg: false,
    // ローディング画面表示フラグ
    loadingCounter: 0,
    u_10: false,
    // 取引先
    suppliersSelected: "",
    supplierList: [],
    alertSupplierMessage: "",
    // 入荷元
    arrivalSelected: "",
    arrivalList: [],
    // 入荷No
    arrivalNo: [],
    // 入荷予定日From
    arrivalScheduleDateFromCal: "",
    arrivalScheduleDateFrom: "",
    dateMenuFrom: false,
    // 入荷予定日To
    arrivalScheduleDateToCal: "",
    arrivalScheduleDateTo: "",
    dateMenuTo: false,
    // ページング
    itemsPerPageList: [10, 50, 100],
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    itemsPerPageBtn: "10",
    // 総件数
    totalCount: 0,
    // メニュー
    openMenu: null,
    // 確認メッセージダイアログ
    infoDialog: {
      isOpen: false,
      title: "",
      message: "",
      firstPageFlag: false,
    },
    confirmDialog: {
      message: "",
      redMessage: "",
      isOpen: false,
      okAction: () => {},
    },
    // 一覧
    inputList: [],
    // チェック済リスト
    sendList: [],
    // バリデーションチェックメッセージダイアログ
    alertMessage: "",
    // 画面遷移用チェックボックスリスト
    listCheckbox: [],
    // 編集用チェックボックスリスト
    chengeListCheckbox: [],
    // 戻るボタンダイアログ
    updateDialogMessage: false,
    // 戻るボタンダイアログメッセージ
    backMessage: "",
    // ソート
    sortItem: "",
    sortOptions: {},
    // 初期データ
    defaultData: [],
    // ヘッダ
    headerItems: [
      { text: "選択", value: "check", width: "7%", align: "center", sortable: false },
      // 入荷予定日
      {
        text: i18n.tc("label.lbl_arrivalScheduleDate"),
        value: "arrivalScheduleDateJ",
        width: "7%",
        align: "left",
        sortable: true,
      },
      // 入荷No
      {
        text: i18n.tc("label.lbl_slipNo"),
        value: "arrivalNo",
        width: "7%",
        align: "left",
        sortable: true,
      },
      // 入荷元
      {
        text: i18n.tc("label.lbl_arrival"),
        value: "arrivalNm",
        width: "17%",
        align: "left",
        sortable: true,
      },

      // 有償/無償
      {
        text: i18n.tc("label.lbl_isPaid"),
        value: "isPaid",
        width: "7%",
        align: "left",
        sortable: true,
      },
      // 処理区分
      {
        text: i18n.tc("label.lbl_processingdivision"),
        value: "processingdivision",
        width: "7%",
        align: "left",
        sortable: true,
      },
      // 入庫済
      {
        text: i18n.tc("label.lbl_enterQty"),
        value: "exted",
        width: "7%",
        align: "center",
        sortable: true,
      },
    ],

    // 入力チェック
    rules: {
      inputRequired: (value) => !!value || i18n.tc("check.chk_input"),
      yyyymmdd: (value) =>
        (dateTimeHelper.validDate(commonUtil.zen2han(value), "/") &&
          dateTimeHelper.isDate(value)) ||
        i18n.tc("check.chk_inputDate"),
    },
    getSuppliersSelected: "",
    productHintArrival: "",
    // 検索時の保持
    arrivalSelectedSearchCondition: "",
    arrivalListSearchCondition: [],
    searchArrivalScheduleDateFrom: "",
    searchArrivalScheduleDateFromCal: "",
    searchArrivalScheduleDateTo: "",
    searchArrivalScheduleDateToCal: "",
    searchArrivalNo: "",
  }),

  created() {
    // 取引先SID
    this.suppliersSelected = this.$route.params.searchSuppliersSelected;
    // 入荷元SID
    this.arrivalSelected = this.$route.params.searchArrivalSelected;
    // 入荷予定日From
    this.arrivalScheduleDateFrom = this.$route.params.searchArrivalScheduleDateFrom;
    // 入荷予定日FromCal
    this.arrivalScheduleDateFromCal = this.$route.params.searchArrivalScheduleDateFromCal;
    // 入荷予定日To
    this.arrivalScheduleDateTo = this.$route.params.searchArrivalScheduleDateTo;
    // 入荷予定日ToCal
    this.arrivalScheduleDateToCal = this.$route.params.searchArrivalScheduleDateToCal;
    this.checkFlg = this.$route.params.checkFlg;
    // 入荷No
    this.arrivalNo = this.$route.params.searchArrivalNo;
    if (this.suppliersSelected) {
      this.productHintArrival = this.$route.params.arrivalNm ? this.$route.params.arrivalNm : "";
      let searchArrivalList = this.$route.params.searchArrivalList;
      this.arrivalSelectedSearchCondition = this.arrivalSelected;
      this.arrivalListSearchCondition = searchArrivalList;
      this.arrivalList = searchArrivalList ? searchArrivalList : [];
      this.getApiList(true);
    }
  },

  methods: {
    u_10_check() {
      if (this.itemsPerPage < 11) {
        this.u_10 = true;
      }
    },
    u_10_on() {
      this.u_10 = true;
      this.itemsPerPage = 10;
      this.page = 1;
      this.getApiList(false);
    },
    u_10_clear(val) {
      this.u_10 = false;
      this.itemsPerPage = val;
      this.page = 1;
      this.getApiList(false);
    },

    /**
     * 初期データ取得
     */
    getCustomInfo() {
      // ローディング画面表示ON
      this.loadingCounter = 1;

      // 取引先
      const clientList = getParameter.getRelatedBiz(
        appConfig.ISGETCLIENT.CLIENT,
        dateTimeHelper.convertUTC()
      );
      // // From（入荷元）
      // const fromList = getParameter.getRelatedBiz(
      //   appConfig.ISGETCLIENT.FROMTO,
      //   dateTimeHelper.convertUTC()
      // );
      Promise.all([clientList])
        .then((result) => {
          // 画面の初期値を設定します。
          this.supplierList = result[0];
          // 取引先が1件の場合、取引先を自動選択する
          this.supplierList.length == 1
            ? (this.suppliersSelected = this.supplierList[0].value)
            : "";

          if (this.$route.params.searchSuppliersSelected) {
            // this.getSuppliersSelected();
          }
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.screenFlag = true;
          this.infoDialog.firstPageFlag = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },
    getMstCode() {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      // 発行条件（送り状・荷札）
      const termsList = getParameter.getCodeMst(appConfig.CODETYPE.OUT_INVOICE_DIV);

      Promise.all([termsList])
        .then((result) => {
          // 画面の初期値を設定します。
          this.issueTermsList = result[0];
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.screenFlag = true;
          this.infoDialog.firstPageFlag = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },

    /**
     * 警告ダイアログ、OKボタン押下
     */
    updateOk() {
      this.updateDialogMessage = false;
    },

    /**
     *  検索ボタン押下処理（入庫実績GETAPI）
     */
    getApiList(checkBoxFlag) {
      this.alertSupplierMessage = "";
      this.alertMessage = "";
      // let toDateCheck = dateTimeHelper.validDate(
      //   commonUtil.zen2han(this.arrivalScheduleDateTo),
      //   "/"
      // );
      // let fromDateCheck = dateTimeHelper.validDate(
      //   commonUtil.zen2han(this.arrivalScheduleDateFrom),
      //   "/"
      // );
      // 入力チェック結果フラグ
      let inputCheckResult = false;
      if (this.suppliersSelected == undefined || this.suppliersSelected == null) {
        this.alertSupplierMessage = i18n.tc("check.chk_inputSupplier");
        return;
      }
      if (
        // FromToの日付、入力チェック
        new Date(this.arrivalScheduleDateFrom).getTime() >
        new Date(this.arrivalScheduleDateTo).getTime()
      ) {
        this.alertMessage = i18n.tc("check.chk_inputFromVali");
        // } else if (!toDateCheck || !fromDateCheck) {
        //   this.alertMessage = i18n.tc("check.chk_inputDate");
      } else {
        /**
         * 登録画面からの遷移の場合、入力チェックを実施するとvalidateが定義されていないと
         * エラーがでるので回避するためこのようなコードとなっている。
         */
        if (this.$route.params.searchSuppliersSelected && this.checkFlg) {
          // 登録画面からの遷移の場合、入力チェックは実施しない。
          inputCheckResult = true;
        } else {
          // 上記外の場合はチェックを行う。
          inputCheckResult = this.$refs.form.validate();
        }
        // 画面遷移フラグをfalseに戻す
        this.checkFlg = false;
        // 入力チェック実施
        if (inputCheckResult) {
          // バリデーションエラーメッセージを非表示
          this.alertMessage = "";

          // ローディング画面表示ON
          this.loadingCounter = 1;

          // httpClientで使用する共通IFパラメータ等を作成する。
          const config = this.$httpClient.createGetApiRequestConfig();

          // 取引先SID
          config.params.clientSid = this.suppliersSelected;
          if (this.arrivalScheduleDateFrom) {
            // From日
            config.params.inScheduleDateFrom = dateTimeHelper.convertUTC(
              this.arrivalScheduleDateFrom
            );
          }
          if (this.arrivalScheduleDateTo) {
            // To日
            var arr = this.arrivalScheduleDateTo + " 23:59:59.999";
            config.params.inScheduleDateTo = dateTimeHelper.convertUTC(arr);
          }
          // 入荷元
          if (this.arrivalSelected) {
            config.params.fromSid = this.arrivalSelected;
          }
          // 入荷No
          if (this.arrivalNo) {
            config.params.inListNo = this.arrivalNo;
          }

          // ページャー処理
          if (this.page >= 2 && this.inputList.length == 0) {
            config.params.reqComPageIndex = "1";
            this.page = 1;
          } else {
            config.params.reqComPageIndex = this.page;
          }
          // ページング
          config.params.reqComPaginationFlg = "1";
          config.params.reqComPageLimit = this.itemsPerPage;

          // ソート
          if (this.sortItem != "") {
            config.params.reqComSortItem = this.sortItem;
          }
          // 検索条件保持
          this.arrivalSelectedSearchCondition = this.arrivalSelected;
          this.arrivalListSearchCondition =
            this.arrivalSelected == "" || this.arrivalSelected == null
              ? []
              : new Array(...this.arrivalList);
          this.searchArrivalScheduleDateFrom = this.arrivalScheduleDateFrom;
          this.searchArrivalScheduleDateFromCal = this.arrivalScheduleDateFromCal;
          this.searchArrivalScheduleDateTo = this.arrivalScheduleDateTo;
          this.searchArrivalScheduleDateToCal = this.arrivalScheduleDateToCal;
          this.searchArrivalNo = this.arrivalNo;
          return new Promise((resolve, reject) => {
            this.$httpClient
              // 接続先のAPI_URLを入力
              .secureGet(appConfig.API_URL.BIZ_INORDERSACHIEVEMWNTHEADER, config)
              .then((response) => {
                const jsonData = JSON.parse(JSON.stringify(response.data));
                // console.debug("getApiList(checkBoxFlag) Response", response);

                // 成功
                if (jsonData.resCom.resComCode == "000") {
                  this.pageCount = jsonData.resCom.resComPagination.totalPage;
                  this.totalCount = jsonData.resCom.resComPagination.totalRecord;
                  const list = [];
                  let exited = "";
                  // 入庫実績リストを取得
                  jsonData.resIdv.wareInOrdersAchievementHeader.forEach((row) => {
                    if (row.completeFlg == "1") {
                      // 入庫済あり
                      exited = i18n.tc("label.lbl_Ent");
                    } else {
                      // 入庫済なし
                      exited = i18n.tc("label.lbl_unEnt");
                    }

                    list.push({
                      // 日時（API用）
                      arrivalScheduleDate: row.inScheduleDate,
                      // 日時（画面表示用）
                      arrivalScheduleDateJ: dateTimeHelper
                        .convertUTC2JST(row.inScheduleDate)
                        .substr(0, 10),
                      // 入荷伝票No
                      arrivalNo: row.inListNo,
                      // 入荷伝票SID
                      inListSid: row.inListSid,
                      // FromSID
                      fromSid: row.fromSid,
                      // 入荷元名
                      arrivalNm: row.fromName,
                      //入荷元コード
                      fromBaseCd: row.fromBaseCd,
                      // 処理区分
                      processDiv: row.processDiv,
                      // 処理区分名
                      processingdivision: row.processDivName,
                      // 有償無償区分
                      freeCostDiv: row.freeCostDiv,
                      // 有償無償区分名
                      isPaid: row.freeCostDivName,
                      // 入庫済
                      exted: exited,
                      // チェックボックス
                      check: false,
                      // ステータス
                      completeFlg: row.completeFlg,
                      // 更新日時
                      updateDatetime: row.updateDatetime,
                      // 責任区分
                      blameDiv: row.blameDiv,
                      // 取引先SID
                      clientSid: this.suppliersSelected,
                    });
                  });
                  this.inputList = list;
                  if (!checkBoxFlag) {
                    // 検索ボタン以外で検索処理が走った場合、元々チェックしていたレコードにチェックをいれる
                    for (var i = 0; i < this.inputList.length; i++) {
                      for (var j = 0; j < this.listCheckbox.length; j++) {
                        if (this.inputList[i].inListSid == this.listCheckbox[j].inListSid) {
                          this.inputList[i].check = true;
                        }
                      }
                    }
                  } else {
                    // 再検索前のチェックした項目をリセットする
                    this.listCheckbox.splice(0);
                  }

                  resolve(response);
                } else {
                  // エラー時
                  this.infoDialog.message = jsonData.resCom.resComMessage;
                  this.infoDialog.title = appConfig.DIALOG.title;
                  this.infoDialog.isOpen = true;
                  this.infoDialog.screenFlag = true;
                  this.infoDialog.firstPageFlag = true;

                  reject();
                }
              })
              .catch((ex) => {
                this.infoDialog.message = ex;
                this.infoDialog.title = appConfig.DIALOG.title;
                this.infoDialog.isOpen = true;
                this.infoDialog.screenFlag = true;
                this.infoDialog.firstPageFlag = true;

                reject();
              })
              .finally(() => {
                // ローディング画面表示OFF
                this.loadingCounter = 0;
              });
          });
        }
      }
    },

    /**
     * チェックボックス押下処理
     */
    checkList(item, index) {
      if (!item.check) {
        // チェックを入れた場合

        // 編集用リスト
        this.chengeListCheckbox.push(item);

        // 画面遷移用リスト
        this.listCheckbox.push(item);
      } else {
        // チェックを外した場合

        // 外したレコードの入荷SIDを取得
        let inputListInListSid = this.inputList[index].inListSid;

        // 画面遷移用リストを初期化
        this.listCheckbox = [];

        // チェックを外したレコード以外を画面遷移用リストに詰めなおす
        for (var i = 0; i < this.chengeListCheckbox.length; i++) {
          if (this.chengeListCheckbox[i].inListSid != inputListInListSid) {
            this.listCheckbox.push(this.chengeListCheckbox[i]);
          }
        }

        // 編集用リストも更新
        this.chengeListCheckbox = [];
        for (var j = 0; j < this.listCheckbox.length; j++) {
          this.chengeListCheckbox.push(this.listCheckbox[j]);
        }
      }
    },

    /**
     * 初期値
     */
    init() {
      // 初期データ保持
      this.defaultData = Object.assign({}, this.$data);

      if (this.suppliersSelected) {
        // console.debug("入庫実績登録から遷移");
        // 遷移フラグ
        this.transitionFlg = true;
      } else {
        this.arrivalSelected = "";
        this.shippingScheduleDate = "";
        this.arrivalNo = "";
        this.inputList = [];
        this.page = 1;
        this.itemsPerPage = 10;
        this.itemsPerPageBtn = "10";
        this.arrivalScheduleDateTo = dateTimeHelper.convertJST();
        this.arrivalScheduleDateFrom = dateTimeHelper.convertJST();
        this.arrivalScheduleDateToCal = dateTimeHelper.dateCalc(dateTimeHelper.convertJST());
        this.arrivalScheduleDateFromCal = dateTimeHelper.dateCalc(dateTimeHelper.convertJST());
      }
      this.getCustomInfo();
      this.getMstCode();
    },

    /**
     * 入庫実績登録画面を開きます。
     */
    openUpdateDelete() {
      let toDateCheck = dateTimeHelper.validDate(
        commonUtil.zen2han(this.arrivalScheduleDateTo),
        "/"
      );
      let fromDateCheck = dateTimeHelper.validDate(
        commonUtil.zen2han(this.arrivalScheduleDateFrom),
        "/"
      );

      if (this.suppliersSelected == undefined || this.suppliersSelected == null) {
        this.alertSupplierMessage = i18n.tc("check.chk_input");
        return;
      }
      if (
        // FromToの日付、入力チェック
        new Date(this.arrivalScheduleDateFrom).getTime() >
        new Date(this.arrivalScheduleDateTo).getTime()
      ) {
        this.alertMessage = i18n.tc("check.chk_inputFromVali");
      } else if (!toDateCheck || !fromDateCheck) {
        this.alertMessage = i18n.tc("check.chk_inputDate");
      } else {
        if (this.listCheckbox.length == 1) {
          if (this.listCheckbox[0].completeFlg == "0") {
            // チェックしたリストを追加
            for (var i = 0; i < this.listCheckbox.length; i++) {
              this.sendList.push(this.listCheckbox[i]);
            }

            // 取引先名と取引先コードを取得
            for (var k = 0; k < this.supplierList.length; k++) {
              if (this.supplierList[k].value == this.listCheckbox[0].clientSid) {
                this.suppliersNm = this.supplierList[k].text;
              }
            }

            // 入荷元名と入荷先コードを取得
            // for (var j = 0; j < this.arrivalList.length; j++) {
            //   if (this.arrivalList[j].value == this.listCheckbox[0].fromSid) {
            //     this.arrivalNm = this.arrivalList[j].text;
            //   }
            // }

            // 実績登録画面へ渡す値を設定します。
            this.$router.push({
              name: appConfig.SCREEN_ID.P_ENT_002,
              params: {
                // 実績登録（検索）検索条件保持
                // 取引先SID
                suppliersSelected: this.listCheckbox[0].clientSid,
                // 入荷元SID
                arrivalSelected: this.arrivalSelectedSearchCondition,
                // 入荷日From
                arrivalScheduleDateFrom: this.searchArrivalScheduleDateFrom,
                // 入荷日Fromカレンダー
                arrivalScheduleDateFromCal: this.searchArrivalScheduleDateFromCal,
                // 入荷日To
                arrivalScheduleDateTo: this.searchArrivalScheduleDateTo,
                // 入荷日Toカレンダー
                arrivalScheduleDateToCal: this.searchArrivalScheduleDateToCal,
                // 入荷No
                searchArrivalNo: this.searchArrivalNo,
                // 入荷元一覧
                arrivalList: this.arrivalListSearchCondition,

                // 実績登録画面の検索条件
                // 画面表示用取引先名
                suppliersNm: this.suppliersNm,
                // 画面表示用入荷元名
                arrivalNm:
                  commonUtil.omit(
                    this.listCheckbox[0].fromBaseCd,
                    appConfig.CONSTANT.BASE_CD_DISPLAY_LENGTH
                  ) +
                  "（" +
                  this.listCheckbox[0].arrivalNm +
                  "）",
                // 入荷実績日
                arrivalAchieveDate: this.listCheckbox[0].arrivalScheduleDateJ,
                // 入荷No
                arrivalNo: this.listCheckbox[0].arrivalNo,
                // 有償無償名
                isPaidNm: this.listCheckbox[0].isPaid,
                // 処理区分名
                processDivSelected: this.listCheckbox[0].processingdivision,

                // 入庫実績登録で使用する項目
                // 入荷伝票SID
                inListSid: this.listCheckbox[0].inListSid,
                // 入荷元SID
                fromSid: this.listCheckbox[0].fromSid,
                // 有償無償区分
                freeCostDiv: this.listCheckbox[0].freeCostDiv,
                // 処理区分
                processDiv: this.listCheckbox[0].processDiv,
                // 更新日時
                updateDatetime: this.listCheckbox[0].updateDatetime,
                // 責任区分
                blameDiv: this.listCheckbox[0].blameDiv,
                // 画面遷移判断フラグ
                checkFlg: this.checkFlg,
              },
            });
          } else {
            // 入庫済を選択した場合、警告ダイアログ表示
            this.updateDialogMessage = true;
            this.backMessage = messsageUtil.getMessage("P-ENT-001_001_E");
          }
        } else if (this.listCheckbox.length > 1) {
          // チェックを複数つけた場合、警告ダイアログ表示
          this.updateDialogMessage = true;
          this.backMessage = messsageUtil.getMessage("P-EXT-001_001_E");
        } else {
          // チェックをつけないでボタンを押下した場合、警告ダイアログ表示
          this.updateDialogMessage = true;
          this.backMessage = messsageUtil.getMessage("P-EXT-003_001_E");
        }
      }
    },

    /**
     * メニューダイアログ
     */
    showMenu() {
      this.openMenu = !this.openMenu;
    },

    /**
     * 入荷日付Fromを－１日します。
     */
    prevDate() {
      if (this.arrivalScheduleDateFromCal == null) {
        return;
      }
      let date = new Date(this.arrivalScheduleDateFromCal);
      let toDate = dateTimeHelper.dateCalc(date.setDate(date.getDate()));
      if (this.arrivalScheduleDateFromCal != toDate) {
        this.arrivalScheduleDateFromCal = toDate;
      } else {
        this.arrivalScheduleDateFromCal = dateTimeHelper.dateCalc(date.setDate(date.getDate() - 1));
      }
    },

    /**
     * 入荷日付Fromを＋１日します。
     */
    nextDate() {
      if (this.arrivalScheduleDateFromCal == null) {
        return;
      }
      let date = new Date(this.arrivalScheduleDateFromCal);
      let toDate = dateTimeHelper.dateCalc(date.setDate(date.getDate()));
      if (this.arrivalScheduleDateFromCal != toDate) {
        this.arrivalScheduleDateFromCal = dateTimeHelper.dateCalc(date.setDate(date.getDate() + 2));
      } else {
        this.arrivalScheduleDateFromCal = dateTimeHelper.dateCalc(date.setDate(date.getDate() + 1));
      }
    },

    /**
     * 入荷日付Fromを－１日します。
     */
    prevToDate() {
      if (this.arrivalScheduleDateToCal == null) {
        return;
      }
      let date = new Date(this.arrivalScheduleDateToCal);
      let toDate = dateTimeHelper.dateCalc(date.setDate(date.getDate()));
      if (this.arrivalScheduleDateToCal != toDate) {
        this.arrivalScheduleDateToCal = toDate;
      } else {
        this.arrivalScheduleDateToCal = dateTimeHelper.dateCalc(date.setDate(date.getDate() - 1));
      }
    },

    /**
     * 入荷日付Fromを＋１日します。
     */
    nextToDate() {
      if (this.arrivalScheduleDateToCal == null) {
        return;
      }
      let date = new Date(this.arrivalScheduleDateToCal);
      let toDate = dateTimeHelper.dateCalc(date.setDate(date.getDate()));
      if (this.arrivalScheduleDateToCal != toDate) {
        this.arrivalScheduleDateToCal = dateTimeHelper.dateCalc(date.setDate(date.getDate() + 2));
      } else {
        this.arrivalScheduleDateToCal = dateTimeHelper.dateCalc(date.setDate(date.getDate() + 1));
      }
    },

    /**
     * カレンダコンポーネントの日付はyyyy-MM-ddのためyyyy/MM/ddに変換する。
     */
    formatDate(date) {
      if (!date) return null;

      // 日付の正規表現（yyyy/MM/dd）
      const regex = /^[0-9]{4}\/(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])$/;

      // 対象の文字列チェック
      if (regex.test(date)) {
        // 上記の日付の形で入ってきた場合、そのまま返却。（手入力した場合）
        return date;
      } else {
        // 上記以外の形で入ってきた場合は、スラッシュ編集で返却（データピッカーで日付を選んだ場合）
        const [year, month, day] = date.split("-");
        return `${year}/${month}/${day}`;
      }
    },

    /**
     * 日付入力(From)
     */
    changeDateFrom(val) {
      var strDt = null;
      var inputVal = commonUtil.zen2han(val);
      if (inputVal.length == 8) {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(4, 2) + "/" + inputVal.substr(6, 2);
      } else {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(5, 2) + "/" + inputVal.substr(8, 2);
      }
      if (dateTimeHelper.validDate(strDt, "/")) {
        this.arrivalScheduleDateFromCal = dateTimeHelper.dateCalc(
          dateTimeHelper.convertUTC2JST(strDt.substr(0, 10))
        );
      } else {
        this.arrivalScheduleDateFromCal = null;
      }
    },

    /**
     * 日付入力(To)
     */
    changeDateTo(val) {
      var strDt = null;
      var inputVal = commonUtil.zen2han(val);
      if (inputVal.length == 8) {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(4, 2) + "/" + inputVal.substr(6, 2);
      } else {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(5, 2) + "/" + inputVal.substr(8, 2);
      }
      if (dateTimeHelper.validDate(strDt, "/")) {
        this.arrivalScheduleDateToCal = dateTimeHelper.dateCalc(
          dateTimeHelper.convertUTC2JST(strDt.substr(0, 10))
        );
      } else {
        this.arrivalScheduleDateToCal = null;
      }
    },

    /**
     * 取引先コードリストボックス取得処理
     */
    setSrhSupplier() {
      for (var i = 0; i < this.supplierList.length; i++) {
        if (this.supplierList[i].value == this.suppliersSelected) {
          return this.supplierList[i].name;
        }
      }
    },

    /**
     * 入荷元コードリストボックス取得処理
     */
    setSrhArrival() {
      for (var i = 0; i < this.arrivalList.length; i++) {
        if (this.arrivalList[i].value == this.arrivalSelected) {
          return this.arrivalList[i].name;
        }
      }
    },
    /**
     * プルダウンチェンジイベント
     */
    changeArrival(value) {
      // 既存品番/品名を選択時
      let val = this.arrivalList.find((v) => v.value == value);
      if ((val ?? false) && value != "") {
        this.productHintArrival = val.displayText;
        this.getSuppliersSelected = val.displayText;
      }
      this.arrivalList = this.arrivalList.filter((v) => v.value == value);

      // 値が設定されていない場合はクリアする
      if (value == "" || value == null) {
        this.productHintArrival = "";
      } else {
        // 処理なし
      }
    },
    /**
     * 画面クリアボタン押下時の処理
     */
    clearScreen() {
      this.confirmDialog.message = messsageUtil.getMessage("P-COM-001_006_C", [
        i18n.tc("btn.btn_clear"),
      ]);
      this.confirmDialog.isOpen = true;
      this.confirmDialog.screenFlag = true;
      this.confirmDialog.changeFlag = false;
      this.confirmDialog.okAction = this.clearScreenOkClick;
    },
    /**
     * 画面クリア
     */
    clearScreenOkClick() {
      // 初期表示の設定
      Object.assign(this.$data, this.defaultData);
      // 画面遷移後の場合
      if (this.transitionFlg) {
        this.suppliersSelected = "";
        // 遷移フラグ
        this.transitionFlg = false;
      }
      // 変更検知用の設定
      this.arrivalScheduleDateFromCal = "";
      this.arrivalScheduleDateToCal = "";
      // 初期化
      this.init();
      // バリデーションチェックをリセット
      this.$refs.form.resetValidation();
    },
  },

  computed: {},
  watch: {
    // eslint-disable-next-line no-unused-vars
    isMenu: function (newValue, oldValue) {
      if (newValue) {
        this.init();
      }
    },
    dialog(val) {
      val || this.close();
    },
    suppliersSelected(newValue, oldValue) {
      // 入荷元リストクリア
      if (oldValue != null && oldValue != "") {
        this.arrivalList = [];
        this.arrivalSelected = "";
      }
      if (this.$route.params.searchSuppliersSelected) {
        // 遷移後
      } else {
        this.alertSupplierMessage = "";
      }
    },
    arrivalScheduleDateTo() {
      this.alertMessage = "";
    },
    arrivalScheduleDateFromCal: {
      handler(val) {
        this.arrivalScheduleDateFrom = this.formatDate(val);
      },
      deep: true,
    },
    arrivalScheduleDateToCal: {
      handler(val) {
        this.arrivalScheduleDateTo = this.formatDate(val);
      },
      deep: true,
    },
    page: function (newValue) {
      this.page = newValue;
      this.getApiList(false);
    },

    sortOptions: {
      handler(items) {
        // console.debug("watch sortByApi", items);
        if (items.sortBy.length < 1) {
          this.sortItem = "";
        } else {
          var sortBy = items.sortBy[0];
          var ascdesc = items.sortDesc[0] ? " desc" : " asc";
          this.sortItem = appConfig.P_ENT_001_SORT[sortBy].replaceAll("{0}", ascdesc); // + ascdesc;
          this.getApiList(false);
        }
      },
      deep: true,
    },
    /**
     * 入荷元取得
     */
    getSuppliersSelected(val) {
      if (val ?? false) {
        if (this.suppliersSelected == null || this.suppliersSelected == "") {
          this.arrivalList = [];
          this.arrivalSelected = "";
          return;
        }
        // 表示内容とヒントが一致している場合は、確定した内容なので変更しない
        if (val == this.productHintArrival) {
          return;
        }
        // スペース入力のみの場合、API側で全件検索となり画面が重くなるためAPIの実行をキャンセル
        if (val.trim().length === 0) {
          return;
        }
        // 入力桁数が定義した数異常の場合検索処理
        if (val.length >= appConfig.CNT_CHAR_RELATED_PARTY) {
          // 選択した会社コードを取得
          let officeCd = this.supplierList.find(
            (element) => element.value === this.suppliersSelected
          ).clientSid;
          // From（入荷元）取得
          const fromList = getParameter.getRelatedBizPartialMatch(
            appConfig.ISGETCLIENT.FROMTO,
            dateTimeHelper.convertUTC(),
            undefined,
            officeCd,
            val
          );
          Promise.all([fromList])
            .then((result) => {
              // 画面の初期値を設定します。
              this.arrivalList = result[0];
            })
            .catch((ex) => {
              this.infoDialog.message = ex;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
            })
            .finally(() => {
              // ローディング画面表示OFF
              this.loadingCounter = 0;
            });
        } else {
          this.arrivalList.length = 0;
          this.arrivalList = [];
        }
      }
    },
  },
  mounted() {
    this.init();
  },
};
</script>
<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
.txt-single ::v-deep {
  padding-right: 0;
  font-size: large;
}

.from_to {
  font-weight: bold;
}

.toatlNum {
  margin-left: 10px;
}

.link {
  text-decoration: none;
}
</style>
